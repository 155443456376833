const icons = {
  'alert': require(`../../assets/icons/icon.alert.png`),
  'back': require(`../../assets/icons/icon.back.png`),
  'edit': require(`../../assets/icons/icon.edit.png`),
  'chats': require(`../../assets/icons/icon.chats.png`),
  'down': require(`../../assets/icons/icon.down.png`),
  'flip-camera': require(`../../assets/icons/icon.flip-camera.png`),
  'help': require(`../../assets/icons/icon.help.png`),
  'home': require(`../../assets/icons/icon.home.png`),
  'mail-white': require(`../../assets/icons/icon.mail-white.png`),
  'mail': require(`../../assets/icons/icon.mail.png`),
  'up': require(`../../assets/icons/icon.up.png`),
  'videocall': require(`../../assets/icons/icon.videocall.png`),
  'view': require(`../../assets/icons/icon.view.png`)
}

export default icons;