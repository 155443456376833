import React, { useRef, useState } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import values from '../../../theme/values'
import { getPixelValue, typography } from '../../../theme/styles'
import { ScrollView } from 'react-native-gesture-handler'
import ReminderRow from '../../../components/reminderRow'
import { reminderRowHeight, reminderRowMargin } from '../../../components/reminderRow'
import ScrollButtons from '../../../components/buttons/scrollButtons'
import { useNavigation } from '@react-navigation/core'
import reminders from '../../../data/reminders'
import ReminderNotification from '../../../components/notifications/reminderNotification'

const reminderHeight = reminderRowHeight + reminderRowMargin;

const HomeReminders = () => {

  const navigation = useNavigation();
  const scrollViewRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(getPixelValue(1000));

  const onViewReminder = (reminder) => {
    navigation.navigate('ReminderDetails', { reminder });
  }

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <ReminderNotification count={reminders.length}></ReminderNotification>
      </View>
      <View>
        <ScrollView
          pagingEnabled={true}
          style={styles.scrollView}
          ref={scrollViewRef}
          onScroll={e => {
            setScrollPosition(e.nativeEvent.contentOffset.y);
            setScrollHeight(e.nativeEvent.contentSize.height - e.nativeEvent.layoutMeasurement.height);
            }}>
          {reminders.map((r, i) =>
            // TODO: I think we could pass whole object instead.
            <ReminderRow key={i} title={r.title} date={r.date} time={r.time} isUrgent={r.isUrgent} onPress={() => onViewReminder(r)}/>
          )}
        </ScrollView>
      </View>
      <ScrollButtons
        scrollViewRef={scrollViewRef}
        elementHeight={reminderHeight}
        scrollPosition={scrollPosition}
        scrollViewHeight={scrollHeight} />
    </View>
  )
}

export default HomeReminders

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: values.laMidBlue,
    borderRadius: values.largeBorderRadius,
    overflow: 'hidden'
  },
  headerContainer: {
    flexDirection: 'row',
  },
  title: {
    color: values.laWhite,
    marginTop: getPixelValue(37),
    marginLeft: getPixelValue(29)
  },
  scrollView: {
    marginLeft: getPixelValue(32),
    marginTop: getPixelValue(36),
    height: reminderHeight * 3
  }
})
