const contacts = [
  { name: 'Annie', image: require('../../assets/profile-pictures/annie.png'), notifications: 3 },
  { name: 'Rich', image: require('../../assets/profile-pictures/rich.png'), notifications: 2 },
  { name: 'Ben', image: require('../../assets/profile-pictures/ben.png'), notifications: 4 },
  { name: 'Jessie', image: require('../../assets/profile-pictures/jessie.png'), notifications: 1 },
  { name: 'Mary (Carer)', image: require('../../assets/profile-pictures/mary.png'), notifications: 0 },
  { name: 'James', image: require('../../assets/profile-pictures/james.png'), notifications: 0 },
  { name: 'Block 20 Group', image: require('../../assets/profile-pictures/block20group.png'), notifications: 0 }
];

export default contacts;