import React, { useState, useEffect } from 'react'
import { View, StyleSheet, Text, TouchableOpacity, Image } from 'react-native'
import { getPixelValue, typography } from '../../theme/styles'
import values from '../../theme/values';
import Avatar from './avatar';
import moment from 'moment'
import { useNavigation } from '@react-navigation/core'

const messageBubbleIcon = require('../../../assets/message-bubble.png');
const callbackBubbleIcon = require('../../../assets/callback-bubble.png');

const getSentTime = (sentTime) => {
  const currentTime = moment();
  sentTime = moment(sentTime);

  const timePassed = moment.duration(sentTime.diff(currentTime, 'minutes'), 'minutes');

  if (timePassed.asMinutes() > -60) { // less than hour ago: humanize
    return timePassed.humanize(true).toUpperCase(); // e.g. 42 minutes ago
  }

  if (currentTime.isSame(sentTime, 'date')) { // on the same day: display only time
    return sentTime.format('h:mma').toUpperCase();
  }

  return sentTime.format('dddd DD MMMM h:mma').toUpperCase();
}

const ChatBubble = (props) => {

  const message = props.currentMessage;
  const isReceived = props.position == 'left'
  const styles = isReceived ? receivedStyles : sentStyles;
  const isLastMessage = !props.nextMessage.text;
  const displayReply = isReceived && isLastMessage;

  const outsideContainerStyles = [
    baseStyles.outsideContainer,
    styles.outsideContainer,
    isLastMessage ? { marginBottom: getPixelValue(103 - 45) } : null
  ];

  const contact = message.user;

  const navigation = useNavigation();
  const onVideoPressed = () => {
    navigation.navigate('Video', { contact });
  }

  const isCallback = message.user.name == "Jessie";

  const replyEvent = isCallback
    ? onVideoPressed
    : props.onReply;

  const replyIcon = isCallback
    ? callbackBubbleIcon
    : messageBubbleIcon;

  const replyIconStyles = isCallback
    ? baseStyles.callbackBubble
    : baseStyles.messageBubble;

  return (
    <View style={outsideContainerStyles}>
      {isReceived
      ? <Avatar image={message.user.avatar} /> 
      : null}
      <View style={[baseStyles.messageDataContainer, styles.messageDataContainer]}>
        <Text style={[typography.regular, baseStyles.messageData]}>
          SENT BY {message.user.name?.toUpperCase() || 'YOU'} - {getSentTime(message.createdAt)}
        </Text>
      </View>
      <View style={styles.messageOuterContainer}>
        {isReceived ? null : <Text style={[typography.regular]}>{message.state.toUpperCase()}</Text>}
        <View style={[baseStyles.messageContainer, styles.messageContainer]}>
          <Text style={[typography.regular, baseStyles.messageText, styles.messageText]}>{message.text}</Text>
        </View>

        { displayReply ?
          <TouchableOpacity style={styles.replyButton} onPress={replyEvent}>
            <Image source={replyIcon} style={replyIconStyles}></Image>
          </TouchableOpacity>
        : null }

      </View>
    </View>
  );
}

export default ChatBubble;

const baseStyles = StyleSheet.create({
  messageBubble: {
    width: getPixelValue(197),
    height: getPixelValue(106)
  },
  callbackBubble: {
    width: getPixelValue(270),
    height: getPixelValue(106)
  },
  outsideContainer: {
    marginTop: getPixelValue(59)
  },
  messageContainer: {
    maxWidth: '66.25%',
    paddingHorizontal: getPixelValue(33),
    paddingVertical: getPixelValue(19),
    borderRadius: values.largeBorderRadius,
    borderTopRightRadius: 0
  },
  messageText: {
    lineHeight: getPixelValue(45),
    fontSize: getPixelValue(30)
  },
  messageDataContainer: {

  },
  messageData: {
    color: values.laBaseBlue
  }
});

const sentStyles = StyleSheet.create({
  outsideContainer: {
    marginRight: getPixelValue(32),
    alignSelf: 'flex-end',
    alignItems: 'flex-end'
  },
  messageContainer: {
    backgroundColor: values.laBgGrey,
    marginLeft: getPixelValue(30)
  },
  messageText: {
    color: values.laBaseBlue
  },
  messageDataContainer: {
    alignSelf: 'flex-end',
    marginBottom: getPixelValue(13)
  },
  messageOuterContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
});

const receivedStyles = StyleSheet.create({
  outsideContainer: {
    paddingLeft: getPixelValue(86),
    flex: 1
  },
  messageContainer: {
    backgroundColor: values.laBaseBlue
  },
  messageText: {
    color: values.laWhite
  },
  messageDataContainer: {
    marginBottom: getPixelValue(9),
    marginLeft: getPixelValue(143 - 86)
  },
  replyButton: {
    width: getPixelValue(155),
    height: getPixelValue(63.7),
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: getPixelValue(-35),
    marginLeft: getPixelValue(-40)
  },
  messageOuterContainer: {
    flexDirection: 'row'
  }
});