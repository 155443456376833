import React from 'react'
import { StyleSheet, View } from 'react-native'
import IconButton from '../../components/buttons/iconButton'
import Logo from '../../components/logo'
import HubLayout from '../../layouts/hubLayout'
import { getPixelValue } from '../../theme/styles'
import HomeLinks from './homeLinks/homeLinks'
import HomeReminders from './homeReminders/homeReminders'

const Home = () => {
  return (
    <HubLayout
      rightHeaderContent={<></>}
      centerHeaderContent={<Logo />}
      leftHeaderContent={<></>}
    >
      <View style={styles.container}>
        <View style={styles.leftContainer}>
          <HomeLinks />
        </View>
        <View style={styles.rightContainer}>
          <HomeReminders />
        </View>
      </View>
    </HubLayout>
  )
}

export default Home

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row'
  },
  leftContainer: {
    flex: 1,
    paddingTop: getPixelValue(32),
    paddingLeft: getPixelValue(32),
    paddingRight: getPixelValue(16),
    paddingBottom: getPixelValue(32)
  },
  rightContainer: {
    flex: 1,
    paddingTop: getPixelValue(32),
    paddingLeft: getPixelValue(16),
    paddingRight: getPixelValue(32),
    paddingBottom: getPixelValue(32)
  }
});
