import { useFonts } from '@expo-google-fonts/rubik'
import React from 'react'
import { Text } from 'react-native';
import Index from './src'
import { fonts } from './src/theme/styles'

export default function App () {
  let [fontsLoaded] = useFonts(fonts);

  if (!fontsLoaded) {
    return <Text>Loading...</Text>;
  } else {
    return (
      <Index />
    );
  }
}
