import React from 'react'
import { StyleSheet, Image } from 'react-native'
import { getPixelValue } from '../../theme/styles';
import values from '../../theme/values';

const Avatar = ({ image, style }) => {
  return (
      <Image source={image} style={[styles.avatarImage, style]} />
  );
}

export default Avatar;

const styles = StyleSheet.create({
  avatarImage: {
    width: getPixelValue(93),
    height: getPixelValue(93),
    borderRadius: getPixelValue(88),
    shadowColor: "rgba(0, 0, 0, 0.16)",
    shadowOffset: {
      width: 0,
      height: getPixelValue(10)
    },
    shadowRadius: getPixelValue(10),
    shadowOpacity: 1,
    borderWidth: getPixelValue(5),
    borderColor: values.laMidBlue,
    position: 'absolute',
    left: getPixelValue(32),
    top: getPixelValue(-20),
    zIndex: 1
  }
});