import React from 'react'
import { Text, StyleSheet, TouchableOpacity, Image, View } from 'react-native'
import values from '../../theme/values'
import { getPixelValue, typography } from '../../theme/styles'

const ColorButton = ({ title, onPress, colorScheme = '', style }) => {

  const containerStyles = [
    styles.container,
    (colorScheme == 'blue' ? styles.containerBlue : null),
    (colorScheme == 'red' ? styles.containerRed : null),
    style
  ];

  const titleStyles = [
    styles.title,
    (colorScheme == 'red' ? styles.titleRed : null)
  ];

  return (
    <TouchableOpacity style={containerStyles} onPress={onPress}>
      <Text style={[typography.medium, titleStyles]}>{title}</Text>
    </TouchableOpacity>
  )
}

export default ColorButton

const styles = StyleSheet.create({
  container: {
    backgroundColor: values.laBgGrey,
    borderRadius: values.smallBorderRadius,
    flexDirection: 'row',
    // paddingRight: getPixelValue(20),
    alignSelf: 'center'
  },
  containerBlue: {
    backgroundColor: values.laLightBlue
  },
  containerRed: {
    backgroundColor: values.laRed
  },
  title: {
    color: values.laBaseBlue,
    paddingVertical: getPixelValue(14),
    alignSelf: 'center'
  },
  titleRed: {
    color: values.laWhite
  }
})
