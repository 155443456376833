import React from 'react'
import { StyleSheet, Text, View, Image } from 'react-native'
import values from '../../theme/values'
import { getPixelValue, typography } from '../../theme/styles'
import icons from '../../data/icons'

const ReminderNotification = ({ count = 0 }) => {
  const reminderText = count == 1
   ? "Reminder"
   : "Reminders";


  return (
    <View style={styles.notificationCountContainer}>
      <Text style={[typography.bold, styles.notificationCount]}>
        {count} {reminderText}
      </Text>
    </View>
  )
}

export default ReminderNotification

const styles = StyleSheet.create({
  notificationCountContainer: {
    backgroundColor: values.laRed,
    flexDirection: 'row',
    padding: getPixelValue(15),
    paddingHorizontal: getPixelValue(40),
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomRightRadius: values.largeBorderRadius
  },
  notificationCount: {
    color: values.laWhite
  },
  iconMsg: {
    width: getPixelValue(45),
    height: getPixelValue(36),
    marginLeft: getPixelValue(10)
  }
})
