import React from 'react'
import { Text, StyleSheet, TouchableOpacity, Image, View } from 'react-native'
import values from '../../theme/values'
import { typography } from '../../theme/styles'
import icons from '../../data/icons'

const NotificationButton = ({ title, icon, onPress }) => {
  const iconSource = icons[icon];

  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <View style={styles.imageContainer}>
        <Image source={iconSource} style={styles.icon}></Image>
      </View>
      <View style={styles.titleContainer}>
        <Text style={[typography.medium, styles.title]}>{title}</Text>
      </View>
    </TouchableOpacity>
  )
}

export default NotificationButton

const styles = StyleSheet.create({
  container: {
    backgroundColor: values.laBgGrey,
    borderRadius: values.smallBorderRadius,
    flexDirection: 'row',
    alignSelf: 'center'
  },
  titleContainer: {
    backgroundColor: values.laBaseBlue,
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 20,
    paddingLeft: 17,
    borderTopRightRadius: values.smallBorderRadius,
    borderBottomRightRadius: values.smallBorderRadius
  },
  title: {
    color: values.laWhite,
    paddingVertical: 14
  },
  icon: {
    width: 30,
    height: 24,
    marginHorizontal: 17,
    marginVertical: 20
  },
  imageContainer: {
    backgroundColor: values.laRed,
    borderTopLeftRadius: values.smallBorderRadius,
    borderBottomLeftRadius: values.smallBorderRadius
  }
})
