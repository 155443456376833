import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { typography } from '../../theme/styles'
import BackButton from '../../components/buttons/backButton'
import HomeButton from '../../components/buttons/homeButton'
import HubLayout from '../../layouts/hubLayout'
import ChatsGrid from './chatsGrid'
import values from '../../theme/values'

const Chats = () => {
  return (
    <HubLayout
      leftHeaderContent={<BackButton />}
      centerHeaderContent={<Text style={[typography.bold, styles.title]}>My Chats</Text>}
      rightHeaderContent={<HomeButton />}
      displayFooter={false}
    >
      <>
        <View style={styles.chatsContainer}>
          <ChatsGrid />
        </View>
      </>
    </HubLayout>
  )
}

export default Chats

const styles = StyleSheet.create({
  chatsContainer: {
    flex: 1
  },
  title: {
    color: values.laWhite
  }
})
