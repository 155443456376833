import React, { useEffect, useState } from 'react'
import { StyleSheet, Text, View, Image } from 'react-native'
import BackButton from '../../components/buttons/backButton'
import HubLayout from '../../layouts/hubLayout'
import HomeButton from '../../components/buttons/homeButton'
import { getPixelValue, typography } from '../../theme/styles'
import values from '../../theme/values'
import ColorButton from '../../components/buttons/colorButton'
import { useNavigation } from '@react-navigation/core'
import Video from './video'
import CallTimer from './callTimer'
import IconButton from '../../components/buttons/iconButton'

let retryCount = 0; // for testing

export const videoCallSteps = {
  calling: 'calling',
  failed: 'failed',
  inCall: 'inCall'
}

const GetDescriptionForStep = (step, contact) => {
  switch (step) {
    case videoCallSteps.calling:
      return `Calling ${contact.name}...`;
    case videoCallSteps.failed:
      return `${contact.name} didn't answer`;
  }
}

const GetFooterCenterForStep = (step, endCallCallback, tryAgainCallback) => {
  switch (step) {
    case videoCallSteps.calling:
      return <ColorButton title='End this call' colorScheme='red' style={{paddingHorizontal: getPixelValue(203), paddingVertical: getPixelValue(14)}} onPress={endCallCallback} />;
    case videoCallSteps.failed:
      return <View style={styles.failedCallFooter}>
        <ColorButton title='Try call again?' colorScheme='blue' style={styles.retryButton} onPress={tryAgainCallback} />
        <ColorButton title='Stop calling' colorScheme='red' style={{paddingHorizontal: getPixelValue(98), paddingVertical: getPixelValue(14)}} onPress={endCallCallback} />
      </View>;
    case videoCallSteps.inCall:
      return <ColorButton title='End this call' colorScheme='red' style={{paddingHorizontal: getPixelValue(203), paddingVertical: getPixelValue(14)}} onPress={endCallCallback} />;
  }
}

const GetFooterLeftForStep = (step) => {
  if (step == videoCallSteps.inCall) {
    return <IconButton title='Use front camera' colorScheme='blue' style={styles.frontCameraButton} iconStyle={styles.flipCameraIcon} icon='flip-camera' />;        
  }

  return null;
}

const GetFooterRightForStep = (step, startTime) => {
  if (step == videoCallSteps.inCall) {
    return <CallTimer style={styles.timer} startTime={startTime} />;        
  }

  return null;
}

const VideoCall = ({ route }) => {
  
  let { contact, step } = route.params;

  let [currentStep, setCurrentStep] = useState(step || videoCallSteps.calling);
  let [callStartTime, setCallStartTime] = useState(0);

  const imageSource = contact.image;

  const navigation = useNavigation();

  const endCallPressed = () => {
    navigation.goBack();
  }

  const tryAgainPressed = () => {
    setCurrentStep(videoCallSteps.calling);
    retryCount++;
  }

  const description = GetDescriptionForStep(currentStep, contact);
  const footerCenterContent = GetFooterCenterForStep(currentStep, endCallPressed, tryAgainPressed);
  const footerLeftContent = GetFooterLeftForStep(currentStep);
  const footerRightContent = GetFooterRightForStep(currentStep, callStartTime);

  useEffect(() => {
    if (currentStep != videoCallSteps.calling) {
      return;
    }

    const timeout = setTimeout(() => {
      if (retryCount >= 1) {
        setCurrentStep(videoCallSteps.inCall);
        setCallStartTime(new Date());
        return;
      }
      setCurrentStep(videoCallSteps.failed);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [currentStep]);

  const content = currentStep == videoCallSteps.inCall
    ? <Video />
    : <View style={styles.container}>
        <View style={styles.topRow}>
          <Image style={styles.image} source={imageSource} />
        </View>
        <View style={styles.bottomRow}>
          <Text style={[typography.bold, styles.description]}>{description}</Text>
        </View>
      </View>

  return (
    <HubLayout
      leftHeaderContent={<BackButton />}
      centerHeaderContent={<Text style={[typography.bold, styles.title]}>Video call {contact.name}</Text>}
      rightHeaderContent={<HomeButton />}
      displayFooter={true}
      footerHeight={getPixelValue(143.4)}
      leftFooterContent={footerLeftContent}
      centerFooterContent={footerCenterContent}
      rightFooterContent={footerRightContent}
    >
      {content}
    </HubLayout>
  );
}

export default VideoCall

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  title: {
    color: values.laWhite
  },
  topRow: {
    marginTop: getPixelValue(162),
    alignItems: 'center'
  },
  description: {
    color: values.laBaseBlue
  },
  image: {
    width: getPixelValue(360),
    height: getPixelValue(360),
    borderRadius: getPixelValue(24),
    shadowColor: "rgba(0, 0, 0, 0.1)",
    shadowOffset: {
      width: 0,
      height: getPixelValue(4)
    },
    shadowRadius: getPixelValue(10),
    shadowOpacity: 1
  },
  bottomRow: {
    marginTop: getPixelValue(114),
    flexDirection: 'row',
    alignSelf: 'center',
    // columnGap: 180
  },
  failedCallFooter: {
    flexDirection: 'row',
    // columnGap: 141
  },
  flipCameraIcon: {
    width: getPixelValue(29.1),
    height: getPixelValue(40),
    marginVertical: getPixelValue(28),
    marginLeft: getPixelValue(30),
    marginRight: getPixelValue(20.5)
  },
  frontCameraButton: {
    paddingRight: getPixelValue(30),
    alignSelf: 'flex-start',
    marginLeft: getPixelValue(32)
  },
  timer: {
    marginRight: getPixelValue(46),
    fontSize: getPixelValue(38),
    textAlign: 'left'
  },
  retryButton: {
    paddingHorizontal: getPixelValue(98),
    paddingVertical: getPixelValue(14),
    marginRight: getPixelValue(141)
  }
});