import React from 'react'
import { StyleSheet, View } from 'react-native'

import Logo from '../components/logo'
import Header from './header'
import Date from './footer/date'
import Footer from './footer/footer'
import Time from './footer/time'
import UserTile from './footer/userTile'
import { getPixelValue } from '../theme/styles'
import values from '../theme/values'


const HubLayout = ({
  style,
  children,
  rightHeaderContent = <></>,
  centerHeaderContent = <Logo />,
  leftHeaderContent= <></>,
  leftFooterContent = <UserTile />,
  centerFooterContent =<Time />,
  rightFooterContent = <Date />,
  displayFooter = true,
  footerHeight = getPixelValue(112)
}) => {
  return (
    <View style={[styles.container, style]}>
      <Header
        rightContent={rightHeaderContent}
        centerContent={centerHeaderContent}
        leftContent={leftHeaderContent}
      />
      <View style={styles.centralContainer}>
        {children}
      </View>
      {displayFooter
        ? <Footer
          leftContent={leftFooterContent}
          centerContent={centerFooterContent}
          rightContent={rightFooterContent}
          height={footerHeight} />
        : null}
    </View>
  )
}

export default HubLayout

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: values.laLightGrey
  },
  centralContainer: {
    flex: 1
  }
})
