import React from 'react'
import { StyleSheet, View, Image, Text } from 'react-native'
import { useNavigation } from '@react-navigation/native'

import CardButton from '../../components/buttons/cardButton'
import Logo from '../../components/logo'
import Header from '../../layouts/header'
import Time from '../../layouts/footer/time'
import Date from '../../layouts/footer/date'
import contacts from '../../data/contacts'
import { getPixelValue, typography } from '../../theme/styles'
import values from '../../theme/values'

const profilePicture = require('../../../assets/profile-pictures/vera.png');

const HomeLimited = () => {
  const navigation = useNavigation()

  const linkPressed = (link) => {
    navigation.navigate(link)
  }

  const firstContact = contacts[0];
  const secondContact = contacts[3];
  const thirdContact = contacts[4];

  const otherNotifications = contacts
    .filter(e => ![firstContact, secondContact, thirdContact]
      .map(q => q.name)
      .includes(e.name))
    .map(e => e.notifications)
    .reduce((a, v) => a + v);

  const getCardButtonForContact = (contact) => {
    return <CardButton title={contact.name} image={contact.image} highlighted={contact.name == "Annie"} notificationCount={contact.notifications} onPress={() => onChatPressed(contact)} />;
  };

  const onChatPressed = (contact) => {
    navigation.navigate('Contact', { contact });
  }

  return (
    <View style={styles.container}>
      <Header centerContent={<Logo />} />

      <View style={styles.contacts}>
        {getCardButtonForContact(firstContact)}
        {getCardButtonForContact(secondContact)}
        {getCardButtonForContact(thirdContact)}
        <CardButton title='All Contacts' icon='chats' notificationCount={otherNotifications} onPress={() => { linkPressed('Chats') }} />
      </View>

      <View style={styles.footer}>

        <View style={styles.rowContainer}>
          <Image source={profilePicture} style={styles.profilePicture}></Image>
          <Time style={styles.time} />
        </View>

        <View style={styles.rowContainer}>
          <Text style={[ typography.regular, styles.profileName ]}>Hello Vera</Text>
          <Date style={styles.profileName} />
        </View>

      </View>
    </View>
  )
}

export default HomeLimited

const styles = StyleSheet.create({
  contacts: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    paddingTop: getPixelValue(32),
    paddingLeft: getPixelValue(32),
    paddingRight: getPixelValue(32),
    paddingBottom: getPixelValue(32)
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-around',
    height: getPixelValue(400),
    padding: getPixelValue(60),
    backgroundColor: values.laMidBlue
  },
  container: {
    height: "100%",
    display: 'flex',
    justifyContent: 'space-between'
  },
  profilePicture: {
    width: getPixelValue(154),
    height: getPixelValue(154),
    borderRadius: getPixelValue(77)
  },
  profileName: {
    color: values.laWhite,
    fontSize: getPixelValue(70),
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  time: {
    fontSize: getPixelValue(112)
  }
});