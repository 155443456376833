import { useNavigation } from '@react-navigation/core'
import React, { useState, useRef } from 'react'
import { View, ScrollView, StyleSheet } from 'react-native'
import CardButton from '../../components/buttons/cardButton'
import ColorButton from '../../components/buttons/colorButton';
import IconButton from '../../components/buttons/iconButton';
import ScrollButtons from '../../components/buttons/scrollButtons'
import NotificationButton from '../../components/buttons/notificationButton';
import contacts from '../../data/contacts'
import { getPixelValue } from '../../theme/styles';

const ChatsGrid = () => {

  const [unreadFiltering, setUnreadFiltering] = useState(false);
  const scrollViewRef = useRef(null);
  const navigation = useNavigation();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(getPixelValue(1000));

  const onChatPressed = (contact) => {
    navigation.navigate('Contact', { contact });
  }

  const onNotificationButtonPressed = () => {
    setUnreadFiltering(!unreadFiltering);
  }

  const filteredChats = unreadFiltering ? contacts.filter(c => c.notifications) : contacts;
  const unreadMessagesCount = filteredChats.map(c => c.notifications).reduce((a, b) => a + b);
  let topButton = unreadMessagesCount
    ? <ColorButton
      colorScheme='red'
      title={`${unreadMessagesCount} Unread message${unreadMessagesCount > 1 ? 's' : ''}`}
      onPress={onNotificationButtonPressed}
      style={styles.unreadMessagesButton} />
    : null;

  if (unreadFiltering) {
    topButton = <IconButton icon='chats' iconStyle={{ width: getPixelValue(32) }} title='Show all contacts' onPress={onNotificationButtonPressed} colorScheme='blue' />
  }

  const fillEmptySpace = () => {
    const dummyGridCards = [];
    const elementCount = 4 - ((filteredChats.length) % 4);

    for (let i = 0; i < elementCount; ++ i) {
      dummyGridCards.push(<View key={i} style={styles.dummy} />);
    }

    return dummyGridCards;
  }

  return (
    <View style={styles.container}>
      <View style={styles.topContainer}>
        {topButton}
      </View>
      <ScrollView style={styles.scrollView}
        pagingEnabled={true}
        ref={scrollViewRef}
        onScroll={e => {
          setScrollPosition(e.nativeEvent.contentOffset.y);
          setScrollHeight(e.nativeEvent.contentSize.height - e.nativeEvent.layoutMeasurement.height);
          }}>
        <View style={styles.gridContainer}>
        {
          filteredChats.map((c, idx) => (
            <View 
              key={idx}
              style={styles.card}>
              <CardButton
                title={c.name}
                image={c.image}
                onPress={() => onChatPressed(c)}
                small={true}
                notificationCount={c.notifications} />
            </View>
          ))
        }
        {fillEmptySpace()}
        </View>
      </ScrollView>

      <View style={styles.navigationContainer}>
        <ScrollButtons
            scrollViewRef={scrollViewRef}
            elementHeight={getPixelValue(440)}
            scrollPosition={scrollPosition}
            scrollViewHeight={scrollHeight}
            curvedEdges={false} />
      </View>

    </View>
  )
}

export default ChatsGrid

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  scrollView: {
    marginTop: getPixelValue(56),
    marginBottom: getPixelValue(-56),
    marginHorizontal: getPixelValue(64),
    flexGrow: 1,
    height: getPixelValue(1)
  },
  gridContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  card: {
    marginBottom: getPixelValue(80)
  },
  topContainer: {
    paddingLeft: getPixelValue(64),
    paddingTop: getPixelValue(32),
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  unreadMessagesButton: {
    paddingHorizontal: getPixelValue(20)
  },
  dummy: {
    width: getPixelValue(360),
    height: getPixelValue(360)
  },
  navigationContainer: {
    height: getPixelValue(88)
  }
})
