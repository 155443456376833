import React from 'react'
import { StyleSheet, TouchableOpacity, Image, View } from 'react-native'
import { getPixelValue } from '../../theme/styles';
import values from '../../theme/values'

const upIconSource = require(`../../../assets/icons/icon.up.png`);
const downIconSource = require(`../../../assets/icons/icon.down.png`);

const ScrollButtons = ({
  scrollViewRef,
  elementHeight,
  scrollViewHeight,
  scrollPosition,
  reversed = false,
  curvedEdges = true
}) => {

  const upButtonEnabled = reversed ? scrollPosition < scrollViewHeight : scrollPosition > 0;
  const downButtonEnabled = reversed ? scrollPosition > 0 : scrollPosition < scrollViewHeight;

  function scrollUp() {
    if (scrollViewRef.current.scrollTo) {
      scrollViewRef.current.scrollTo({
        y: scrollPosition - elementHeight
      });

      return;
    }

    scrollViewRef.current.scrollToOffset({
      offset: scrollPosition + elementHeight
    });
  }

  function scrollDown() {
    if (scrollViewRef.current.scrollTo) {
      scrollViewRef.current.scrollTo({
        y: Math.min(scrollViewHeight, scrollPosition + elementHeight)
      });

      return;
    }

    scrollViewRef.current.scrollToOffset({
      offset: scrollPosition - elementHeight
    });
  }

  const downButtonStyles = [
    styles.scrollButton,
    styles.downButton
  ];

  const upButtonStyles = [
    styles.scrollButton,
    styles.upButton
  ];

  const downButtonContainerStyles = [
    styles.buttonContainer,
    styles.downButtonContainer,
    downButtonEnabled ? styles.scrollButtonEnabled : styles.scrollButtonDisabled,
    curvedEdges ? null : styles.downButtonNoCurvedEdge
  ];

  const upButtonContainerStyles = [
    styles.buttonContainer,
    styles.upButtonContainer,
    upButtonEnabled ? styles.scrollButtonEnabled : styles.scrollButtonDisabled,
    curvedEdges ? null : styles.upButtonNoCurvedEdge
  ];

  return (
    <View style={styles.navigationContainer}>
      <View style={downButtonContainerStyles}>
        <TouchableOpacity style={downButtonStyles} onPress={() => scrollDown()}>
          <Image source={downIconSource} style={styles.icon} />
        </TouchableOpacity>
      </View>
      <View style={upButtonContainerStyles}>
        <TouchableOpacity style={upButtonStyles} onPress={() => scrollUp()}>
          <Image source={upIconSource} style={styles.icon} />
        </TouchableOpacity>
      </View>
    </View>
  );
}

export default ScrollButtons

const styles = StyleSheet.create({
  scrollButton: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    alignSelf: 'stretch',
  },
  navigationContainer: {
    flexDirection: 'row',
    flex: 1,
    backgroundColor: values.laWhite
  },
  upButton: {
    borderRightColor: values.laWhite,
    borderRightWidth: getPixelValue(1)
  },
  upButtonNoCurvedEdge: {
    borderBottomRightRadius: 0
  },
  downButton: {
  },
  upButtonContainer: {
    borderBottomRightRadius: values.largeBorderRadius,
    marginLeft: getPixelValue(5)
  },
  downButtonContainer: {
    borderBottomLeftRadius: values.largeBorderRadius
  },
  downButtonNoCurvedEdge: {
    borderBottomLeftRadius: 0
  },
  scrollButtonEnabled: {
    backgroundColor: values.laLightBlue,
    opacity: 1
  },
  scrollButtonDisabled: {
    backgroundColor: values.laLightBlue,
    opacity: 0.5
  },
  icon: {
    height: getPixelValue(48),
    width: getPixelValue(48)
  },
  buttonContainer: {
    flex: 1
  }
})
