import React, { useState } from 'react'
import { StyleSheet, View, Text, Image } from 'react-native'
import HubLayout from '../../layouts/hubLayout'
import values from '../../theme/values'
import { getPixelValue, typography } from '../../theme/styles'
import ColorButton from '../../components/buttons/colorButton'
import { useNavigation } from '@react-navigation/core'
import contacts from '../../data/contacts'
import { videoCallSteps } from '../videoCall/videoCall'

const iconSource = require('../../../assets/icons/icon.mail.png');

const incomingCallSteps = {
  calling: 'calling',
  declined: 'declined',
  confirm: 'confirm'
}

const getTitleForStep = (step, contact) => {
  switch (step) {
    case incomingCallSteps.calling:
      return <Text style={[typography.bold, styles.title]}>{contact.name} is video calling you</Text>;
    case incomingCallSteps.declined:
      return <Text style={[typography.bold, styles.title]}>Let {contact.name} know you are ok?</Text>;
    case incomingCallSteps.confirm:
      return (
      <View style={styles.confirmContainer}>
        <View style={styles.iconContainer}>
          <Image source={iconSource} style={styles.icon} />
        </View>
        <Text style={[typography.bold, styles.confirmTitle]}>Message sent</Text>
      </View>);      
  }
}

const getPageTitleForStep = (step) => {
  switch (step) {
    case incomingCallSteps.calling:
      return 'Incoming call...';
    case incomingCallSteps.declined:
    case incomingCallSteps.confirm:
      return 'Call declined';      
  }
}

const getFirstRowForStep = (step, onAnswer, onWillCallYouBack) => {
  switch (step) {
    case incomingCallSteps.calling:
      return (
      <View style={styles.incomingFirstRowContainer}>
        <ColorButton onPress={onAnswer} title='Answer call' colorScheme='blue' style={{paddingHorizontal: getPixelValue(186), paddingVertical: getPixelValue(14)}} />
      </View>);
    case incomingCallSteps.declined:
      return (
        <View style={styles.incomingFirstRowContainer}>
          <ColorButton onPress={onWillCallYouBack} title='Send a message saying: “I will call you back later”' colorScheme='blue' style={{paddingHorizontal: getPixelValue(69), paddingVertical: getPixelValue(14)}} />
        </View>);
  }
}

const getSecondRowForStep = (step, onDecline, onGoBack) => {
  switch (step) {
    case incomingCallSteps.calling:
      return (
        <View style={styles.incomingSecondRowContainer}>
          <ColorButton onPress={onDecline} title="Can't speak now?" colorScheme='red' style={{paddingRight: getPixelValue(135), paddingLeft: getPixelValue(146), paddingVertical: getPixelValue(14)}} />
        </View>);
    case incomingCallSteps.declined:
    case incomingCallSteps.confirm:
      return (
        <View style={incomingCallSteps.declined ? styles.declinedSecondRowContainer : styles.confirmSecondRowContainer}>
          <ColorButton onPress={onGoBack} title='Go back' style={{paddingHorizontal: getPixelValue(127), paddingVertical: getPixelValue(14)}} />
        </View>);
  }
}

const IncomingCallAlert = ({ route }) => {

  let [currentStep, setCurrentStep] = useState(incomingCallSteps.calling);

  const navigation = useNavigation();

  let contact = route?.params?.contact || contacts[3];
  const imageSource = contact.image;

  const onDecline = () => {
    setCurrentStep(incomingCallSteps.declined);
  }

  const onGoBack = () => {
    navigation.goBack();
  }

  const onWillCallYouBack = () => {
    setCurrentStep(incomingCallSteps.confirm);
  }

  const onAnswer = () => {
    navigation.replace('Video', { contact, step: videoCallSteps.inCall });
  }

  const pageTitle = getPageTitleForStep(currentStep);
  const title = getTitleForStep(currentStep, contact);
  const firstRow = getFirstRowForStep(currentStep, onAnswer, onWillCallYouBack);
  const secondRow = getSecondRowForStep(currentStep, onDecline, onGoBack);

  return (
    <HubLayout
      leftHeaderContent={null}
      centerHeaderContent={<Text style={[typography.bold, styles.pageTitle]}>{pageTitle}</Text>}
      rightHeaderContent={null}
      style={styles.layoutContainer}
      displayFooter={false}
    >
      <View style={styles.container}>
        <View style={styles.imageContainer}>
          <Image style={styles.image} source={imageSource} />
        </View>
        {title}
        {firstRow}
        {secondRow}
      </View>
    </HubLayout>
  )
}

export default IncomingCallAlert;

const styles = StyleSheet.create({
  layoutContainer: {
    backgroundColor: values.laBaseBlue
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageContainer: {
    width: getPixelValue(360),
    height: getPixelValue(360),
    borderRadius: values.largeBorderRadius,
    backgroundColor: values.laRed,
    shadowColor: "rgba(0, 0, 0, 0.1)",
    shadowOffset: {
      width: 0,
      height: getPixelValue(4)
    },
    shadowRadius: getPixelValue(10),
    shadowOpacity: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  image: {
    width: getPixelValue(360),
    height: getPixelValue(360)
  },
  confirmContainer: {
    flexDirection: 'row',
    marginTop: getPixelValue(71)
  },
  confirmTitle: {
    color: values.laWhite,
    alignSelf: 'center',
    marginLeft: getPixelValue(46)
  },
  icon: {
    width: getPixelValue(44),
    height: getPixelValue(36)
  },
  iconContainer: {
    width: getPixelValue(80),
    height: getPixelValue(80),
    backgroundColor: values.laLightBlue,
    shadowColor: "rgba(0, 0, 0, 0.37)",
    shadowOffset: {
      width: 0,
      height: getPixelValue(6)
    },
    shadowRadius: getPixelValue(10),
    shadowOpacity: 1,
    borderRadius: getPixelValue(40),
    justifyContent: 'center',
    alignItems: 'center'
  },
  pageTitle: {
    color: values.laWhite
  },
  title: {
    color: values.laWhite,
    marginTop: getPixelValue(80)
  },
  incomingFirstRowContainer: {
    marginTop: getPixelValue(74)
  },
  declinedSecondRowContainer: {
    marginTop: getPixelValue(128)
  },
  incomingSecondRowContainer: {
    marginTop: getPixelValue(112)
  },
  confirmSecondRowContainer: {
    marginTop: getPixelValue(90)
  }
})
