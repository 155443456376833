import { StyleSheet, PixelRatio } from 'react-native'
import { Rubik_700Bold, Rubik_500Medium, Rubik_400Regular } from '@expo-google-fonts/rubik'

export const getPixelValue = (input) => {
    return input / PixelRatio.get();
}

export const typography = StyleSheet.create({
    regular: {
        fontFamily: 'Rubik_400Regular',
        fontSize: getPixelValue(25),
        letterSpacing: 0.05
    },
    medium: {
        fontFamily: 'Rubik_500Medium',
        fontSize: getPixelValue(30),
        letterSpacing: 0.06
    },
    bold: {
        fontFamily: 'Rubik_700Bold',
        fontSize: getPixelValue(52),
        letterSpacing: 0.1
    }
  });

export const fonts = {
    Rubik_700Bold,
    Rubik_500Medium,
    Rubik_400Regular,
    Rubik_500Medium
};