import React from 'react'
import { StyleSheet, Text, View, TouchableOpacity, Image, ImageBackground } from 'react-native'
import values from '../../theme/values'
import { getPixelValue, typography } from '../../theme/styles'
import MessageNotification from '../notifications/messageNotification'
import icons from '../../data/icons'

const CardButton = ({
  title = '',
  highlighted = false,
  onPress,
  icon,
  image,
  small = false,
  notificationCount = 0,
  iconStyle = null,
  style
}) => {

  const containerStyles = [
    styles.container,
    (highlighted ? styles.containerHighlighted : null),
    (small ? styles.containerSmall : null),
    style
  ];
  const imageStyles = [
    styles.image,
    (highlighted ? styles.imageHighlighted : null),
    (small ? styles.imageSmall : null)
  ];
  const titleContainerStyles = [
    styles.titleContainer,
    (notificationCount ? styles.titleContainerHighlighted : null)
  ];
  const iconStyles = [
    styles.icon,
    iconStyle
  ];

  const iconSource = icons[icon];
  const imageSource = image;

  const titleContainer = <View style={titleContainerStyles}>
    <Text style={[typography.bold, styles.title]}>{title}</Text>
  </View>;

  return (<>
    <TouchableOpacity style={containerStyles} onPress={onPress}>
      {iconSource
        ?
        <View style={styles.iconContainer}>
          <Image source={iconSource} style={iconStyles}></Image>

        </View>
        :
        <>
          <Image source={imageSource} style={imageStyles} resizeMode='cover' />
          <View style={styles.pushTitleDown} />
        </>}
      {titleContainer}

      {notificationCount ?
        <MessageNotification count={notificationCount} />
      : null}

    </TouchableOpacity>


  </>)
}

export default CardButton

const styles = StyleSheet.create({
  container: {
    backgroundColor: values.laLightBlue,
    borderRadius: values.largeBorderRadius,
    overflow: 'hidden',
    borderWidth: getPixelValue(5),
    borderColor: values.laWhite,
    width: getPixelValue(440),
    height: getPixelValue(440),
    backgroundColor: values.laLightBlue,
    shadowColor: "rgba(0, 0, 0, 0.2)",
    shadowOffset: {
      width: 0,
      height: getPixelValue(4)
    },
    shadowRadius: getPixelValue(14),
    shadowOpacity: 1,
    borderStyle: 'solid',
  },
  containerSmall: {
    width: getPixelValue(360),
    height: getPixelValue(360)
  },
  containerHighlighted: {
    borderColor: values.laYellow,
    borderWidth: getPixelValue(10)
  },
  iconContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    height: getPixelValue(114),
    width: getPixelValue(152)
  },
  titleContainer: {
    backgroundColor: values.laBaseBlue,
    height: getPixelValue(128),
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: getPixelValue(15)
  },
  titleContainerHighlighted: {
    backgroundColor: values.laRed
  },
  title: {
    flex: 1,
    textAlign: 'center',
    color: values.laWhite
  },
  image: {
    width: getPixelValue(430),
    position: 'absolute',
    top: 0,
    height: getPixelValue(430),
    shadowColor: "rgba(0, 0, 0, 0.2)",
    shadowOffset: {
      width: 0,
      height: getPixelValue(4)
    },
    shadowRadius: getPixelValue(14),
    shadowOpacity: 1
  },
  imageHighlighted: {
    width: getPixelValue(420),
    height: getPixelValue(420)
  },
  imageSmall: {
    width: getPixelValue(350),
    height: getPixelValue(350)
  },
  pushTitleDown: {
    flex: 1
  },

})
