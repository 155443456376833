import { getPixelValue } from "./styles"

const values = {
  laMidBlue: '#20364D',
  laLightBlue: '#55A7DC',
  laBaseBlue: '#100F24',
  laDarkGrey: '#0e566c',
  laBgGrey: '#A9AEBE',
  laLightGrey: '#dce4e6',
  laRed: '#e25b58',
  laWhite: '#F8F8F8',
  laYellow: '#FFC400',
  largeBorderRadius: getPixelValue(24),
  smallBorderRadius: getPixelValue(8)
}

export default values
