import React from 'react'
import { Image } from 'react-native'
import { getPixelValue } from '../theme/styles';

const logo = require('../../assets/logo.png');

const Logo = ({ width = getPixelValue(112), height = getPixelValue(78.7) }) => {
  return (
    <Image source={logo} style={{ width: width, height: height }} ></Image>
  )
}

export default Logo;