import React, { useState, useEffect } from 'react'
import { StyleSheet, Text } from 'react-native'
import { getPixelValue, typography } from '../../theme/styles'
import moment from 'moment'
import values from '../../theme/values'

const CallTimer = ({
    style
  }) => {

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    let interval = setInterval(() => {
      setCounter(counter + 1);
    }, 1000);

    return () => clearInterval(interval);
  });

  const formattedTime = moment().minute(0).second(counter).format('mm:ss');

  return (
    <Text style={[typography.medium, styles.text, style]}>Call length: {formattedTime}</Text>
  );
}

export default CallTimer

const styles = StyleSheet.create({
  text: {
    color: values.laWhite,
    fontSize: getPixelValue(38),
    textAlign: 'left',
    minWidth: getPixelValue(330)
  }
});