import React from 'react'
import { StyleSheet, Text, View, TouchableOpacity, Image } from 'react-native'
import values from '../theme/values'
import { getPixelValue, typography } from '../theme/styles'

export const reminderRowHeight = getPixelValue(192);
export const reminderRowMargin = getPixelValue(32);

const eyeIcon = require('../../assets/icons/icon.view.png');
const bellIcon = require('../../assets/icons/icon.alert.png');

const ReminderRow = ({
  title = '',
  date = '',
  time = '',
  // TODO: Replace with date/time logic to determine urgency
  isUrgent = false,
  onPress
}) => {

  const backgroundColor = isUrgent
   ? values.laRed
   : values.laLightBlue;

  const iconContainerStyles = [
    styles.rightContainer,
    { backgroundColor: backgroundColor }
  ];

  const iconSource = isUrgent
    ? bellIcon
    : eyeIcon;

  const iconStyles = isUrgent
   ? styles.iconBell
   : styles.iconEye;

   const textColor = isUrgent
    ? values.laWhite
    : values.laBaseBlue;

  return (
    <View style={styles.container}>
      <View style={styles.leftContainer}>
        <View style={styles.titleContainer}>
          <Text style={[typography.medium, styles.title]}>
            {title}
          </Text>
        </View>
        <View style={styles.dateTimeContainer}>
          <Text style={[typography.medium, styles.date]}>
            {date}
          </Text>
          <Text style={[typography.bold, styles.time]}>
            {time}
          </Text>
        </View>
      </View>
      <View style={iconContainerStyles}>
        <TouchableOpacity style={styles.button} onPress={onPress}>
          <Image source={iconSource} style={iconStyles}></Image>
          <Text style={[typography.medium, styles.buttonText, { color: textColor }]}>View</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

export default ReminderRow

const styles = StyleSheet.create({
  container: {
    width: getPixelValue(848),
    overflow: 'hidden',
    height: reminderRowHeight,
    borderWidth: getPixelValue(5),
    marginBottom: reminderRowMargin,
    borderColor: values.laLightGrey,
    borderRadius: values.largeBorderRadius,
    flexDirection: 'row',
    flex: 1
  },
  title: {
    color: values.laWhite,
    fontSize: getPixelValue(38),
    letterSpacing: 0.08
  },
  leftContainer: {
    flex: 1
  },
  titleContainer: {
    backgroundColor: values.laBaseBlue,
    flex: 1,
    justifyContent: 'center',
    paddingLeft: getPixelValue(23),
  },
  rightContainer: {
    width: getPixelValue(160)
  },
  dateTimeContainer: {
    backgroundColor: values.laWhite,
    flexDirection: 'row',
    height: getPixelValue(72),
    alignItems: 'center',
  },
  date:{
    flex: 1,
    color: values.laMidBlue,
    marginLeft: getPixelValue(23),
    fontSize: getPixelValue(38),
    letterSpacing: 0.08
  },
  time: {
    fontSize: getPixelValue(38),
    marginRight: getPixelValue(31),
    letterSpacing: 0.08
  },
  buttonText: {
    marginTop: getPixelValue(16)
  },
  iconEye: {
    height: getPixelValue(48),
    width: getPixelValue(71)
  },
  iconBell: {
    height: getPixelValue(48),
    width: getPixelValue(48)
  },
  button: {
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
