import React from 'react'
import { StyleSheet, Text, View, Image } from 'react-native'
import values from '../../theme/values'
import { getPixelValue, typography } from '../../theme/styles'

const profilePicture = require('../../../assets/profile-pictures/vera.png');

const UserTile = () => {
  return (
    <View style={styles.container}>
      <View style={styles.profilePictureContainer}>
        <Image source={profilePicture} style={styles.profilePicture}></Image>
      </View>
      <View style={styles.profileNameContainer}>
        <Text style={[typography.medium, styles.profileName]}>Hello Vera</Text>
      </View>
    </View>
  )
}

export default UserTile

const styles = StyleSheet.create({
  container: {
    height: getPixelValue(112),
    flexDirection: 'row'
  },
  profilePictureContainer: {
    justifyContent: 'center',
    width: getPixelValue(112),
    height: getPixelValue(112),
    backgroundColor: values.laRed,
    alignItems: 'center'
  },
  profilePicture: {
    width: getPixelValue(112),
    height: getPixelValue(112)
  },
  profileNameContainer: {
    alignItems: 'center',
    paddingHorizontal: getPixelValue(45),
    paddingVertical: getPixelValue(32),
    margin: 0,
    justifyContent: 'center',
    backgroundColor: values.laDarkGrey
  },
  profileName: {
    color: values.laWhite
  }
})
