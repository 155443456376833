const currentDate = new Date();
const millisecondsInMinute = 60000;

const messages = [ {
  _id: 0,
  text: 'Hi Jessie, you are welcome! It was so lovely to have a video call with you yesterday. Did you want to have another one later on? I will be free from 6pm. ',
  createdAt: new Date(currentDate.getTime() - 120 * millisecondsInMinute),
  state: 'sent',
  user: {
    _id: 1
  }
},{
  _id: 1,
  text: 'Hi Granny, hope you are doing ok. Just wanted to say thanks for sending over my birthday present. I love it.',
  createdAt: new Date(currentDate.getTime() - 42 * millisecondsInMinute),
  user: {
    _id: 2
  }
}, {
  _id: 2,
  text: 'Hi Jessie, you are welcome! It was so lovely to have a video call with you yesterday. Did you want to have another one later on? I will be free from 6pm. ',
  createdAt: new Date(currentDate.getTime() - 30 * millisecondsInMinute),
  state: 'sent',
  user: {
    _id: 1
  }
}, {
  _id: 3,
  text: 'Hi Granny, that sounds good to me. I can show you how I have decorated my bedroom.',
  createdAt: new Date(currentDate.getTime() - 25 * millisecondsInMinute),
  user: {
    _id: 2
  }
}];

export default messages;