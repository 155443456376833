import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import CardButton from '../../../components/buttons/cardButton'
import LinkRow from './linkRow'
import contacts from '../../../data/contacts'
import { getPixelValue } from '../../../theme/styles'

const HomeLinks = () => {
  const navigation = useNavigation()

  const linkPressed = (link) => {
    navigation.navigate(link)
  }

  const firstContact = contacts[0];
  const secondContact = contacts[3];
  const thirdContact = contacts[4];

  const onChatPressed = (contact) => {
    navigation.navigate('Contact', { contact });
  }

  const otherNotifications = contacts
    .filter(e => ![firstContact, secondContact, thirdContact]
      .map(q => q.name)
      .includes(e.name))
    .map(e => e.notifications)
    .reduce((a, v) => a + v);

  const getCardButtonForContact = (contact) => {
    return <CardButton title={contact.name} image={contact.image} highlighted={contact.name == "Annie"} notificationCount={contact.notifications} onPress={() => onChatPressed(contact)} />;
  };

  return (
    <View style={styles.container}>
      <LinkRow
        leftLink={getCardButtonForContact(firstContact)}
        rightLink={getCardButtonForContact(secondContact)}
      />
      <View style={styles.linkPadding} />
      <LinkRow
        leftLink={getCardButtonForContact(thirdContact)}
        rightLink={<CardButton title='All Contacts' icon='chats' notificationCount={otherNotifications} onPress={() => { linkPressed('Chats') }} />}
      />
    </View>
  )
}

export default HomeLinks

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  linkPadding: {
    height: getPixelValue(20)
  }
})
