import React from 'react'
import { StyleSheet, Text, View, Image } from 'react-native'
import { getPixelValue } from '../theme/styles'
import values from '../theme/values'

const Header = ({ leftContent, centerContent, rightContent }) => {
  return (
    <View style={styles.container}>
      <View style={styles.leftContainer}>
        {leftContent}
      </View>
      <View style={styles.centerContainer}>
        {centerContent}
      </View>
      <View style={styles.rightContainer}>
        {rightContent}
      </View>
    </View>
  )
}

export default Header

const styles = StyleSheet.create({
  container: {
    height: getPixelValue(112),
    backgroundColor: values.laMidBlue,
    flexDirection: 'row'
  },
  leftContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    paddingLeft: getPixelValue(32),
    flexDirection: 'row'
  },
  centerContainer: {
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center'
  },
  rightContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    paddingRight: getPixelValue(32),
    flexDirection: 'row'
  }
});
