import React, { useEffect, useState } from 'react'
import { StyleSheet, Text } from 'react-native'

import moment from 'moment'
import values from '../../theme/values'
import { typography } from '../../theme/styles'

const getDate = () => moment().format('dddd DD MMMM');

const Date = ({ style }) => {
  const [date, setDate] = useState(getDate());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(getDate());
    }, 1000);

    return () => clearInterval(interval);
  }, [])

  return (
    <>
      <Text style={[typography.medium, styles.date, style]}>{date}</Text>
    </>
  )
}

export default Date

const styles = StyleSheet.create({
  date: {
    color: values.laWhite
  }
})
