import React from 'react'
import { StyleSheet, View, Text, Image } from 'react-native'
import HubLayout from '../../layouts/hubLayout'
import values from '../../theme/values'
import { getPixelValue, typography } from '../../theme/styles'
import BackButton from '../../components/buttons/backButton'
import HomeButton from '../../components/buttons/homeButton'
import { useNavigation } from '@react-navigation/core'
import ColorButton from '../../components/buttons/colorButton'

const iconSource = require('../../../assets/icons/icon.alert.png');

const DiaryAlert = ({ route }) => {

  const navigation = useNavigation();
  let { reminder, isAlert } = route.params;

  const onPressed = () => {
    navigation.goBack()
  }

  const title = isAlert ? 'Reminder Alert' : 'Reminder Information';

  return (
    <HubLayout
      leftHeaderContent={isAlert ? null : <BackButton />}
      centerHeaderContent={<Text style={[typography.bold, styles.pageTitle]}>{title}</Text>}
      rightHeaderContent={isAlert ? null : <HomeButton />}
      style={styles.layoutContainer}
    >
      <View style={styles.container}>
        <View style={styles.iconContainer}>
          <Image style={styles.icon} source={iconSource} />
        </View>
        <Text style={[typography.medium, styles.time]}>{reminder.time}</Text>
        <Text style={[typography.bold, styles.title]}>{reminder.title}</Text>
        <Text style={[typography.medium, styles.description]}>{reminder.description}</Text>
        <View style={styles.doneContainer}>
          <ColorButton onPress={onPressed} title='I have done this' colorScheme='blue' style={{ paddingHorizontal: getPixelValue(207), paddingVertical: getPixelValue(14) }} />
        </View>
        {isAlert ?
          <View style={styles.snoozeContainer}>
            <ColorButton onPress={onPressed} title='Snooze for now' colorScheme='red' style={{ paddingHorizontal: getPixelValue(210), paddingVertical: getPixelValue(14) }} />
          </View>
        : null}
        <View style={styles.cancelContainer}>
          <ColorButton onPress={onPressed} title='Cancel this event' style={{ paddingHorizontal: getPixelValue(20) }} />
        </View>
      </View>
    </HubLayout>
  )
}

export default DiaryAlert;

const styles = StyleSheet.create({
  layoutContainer: {
    backgroundColor: values.laBaseBlue
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  iconContainer: {
    width: getPixelValue(142),
    height: getPixelValue(142),
    borderRadius: values.largeBorderRadius,
    backgroundColor: values.laRed,
    shadowColor: "rgba(0, 0, 0, 0.1)",
    shadowOffset: {
      width: 0,
      height: getPixelValue(4)
    },
    shadowRadius: getPixelValue(10),
    shadowOpacity: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    width: getPixelValue(76.7),
    height: getPixelValue(75)
  },
  time: {
    color: values.laWhite,
    marginTop: getPixelValue(23)
  },
  pageTitle: {
    color: values.laWhite
  },
  title: {
    color: values.laWhite,
    marginTop: getPixelValue(32)
  },
  description: {
    color: values.laWhite,
    marginTop: getPixelValue(43)
  },
  doneContainer: {
    marginTop: getPixelValue(104)
  },
  snoozeContainer: {
    marginTop: getPixelValue(72),
    flexDirection: 'row'
  },
  cancelContainer: {
    alignSelf: 'flex-end',
    position: 'absolute',
    right: getPixelValue(32),
    bottom: getPixelValue(125)
  }
})
