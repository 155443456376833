import React from 'react'
import { StyleSheet, View } from 'react-native'
import { getPixelValue } from '../../../theme/styles'

const LinkRow = ({ leftLink, rightLink }) => {
  return (
    <View style={styles.container}>
      <View style={styles.linkContainerLeft}>
        {leftLink}
      </View>
      <View style={styles.linkContainerRight}>
        {rightLink}
      </View>
    </View>
  )
}

export default LinkRow

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row'
  },
  linkContainerLeft: {
    flex: 1,
    paddingRight: getPixelValue(16)
  },
  linkContainerRight: {
    flex: 1,
    paddingLeft: getPixelValue(16)
  }
})
