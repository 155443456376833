import React from 'react'
import { Text, StyleSheet, TouchableOpacity, Image, View } from 'react-native'
import values from '../../theme/values'
import { getPixelValue, typography } from '../../theme/styles'
import icons from '../../data/icons'

const IconButton = ({ title, icon, onPress, colorScheme = '', style, iconStyle }) => {
  const iconSource = icons[icon];

  const containerStyles = [
    styles.container,
    (colorScheme == 'blue' ? styles.containerBlue : null),
    (colorScheme == 'red' ? styles.containerRed : null),
    style
  ];

  const titleStyles = [
    styles.title,
    (colorScheme == 'red' ? styles.titleRed : null)
  ];

  const iconStyles = [
    styles.icon,
    iconStyle
  ];

  return (
    <TouchableOpacity style={containerStyles} onPress={onPress}>
      {iconSource ? <Image source={iconSource} style={iconStyles}></Image> : null}
      <Text style={[typography.medium, titleStyles]}>{title}</Text>
    </TouchableOpacity>
  )
}

export default IconButton

const styles = StyleSheet.create({
  container: {
    backgroundColor: values.laBgGrey,
    borderRadius: values.smallBorderRadius,
    flexDirection: 'row',
    paddingRight: getPixelValue(20),
    alignSelf: 'center'
  },
  containerBlue: {
    backgroundColor: values.laLightBlue
  },
  containerRed: {
    backgroundColor: values.laRed
  },
  title: {
    color: values.laBaseBlue,
    paddingVertical: getPixelValue(14),
    alignSelf: 'center'
  },
  titleRed: {
    color: values.laWhite
  },
  icon: {
    width: getPixelValue(24),
    height: getPixelValue(24),
    margin: getPixelValue(20)
  }
})
