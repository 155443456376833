import React from 'react'
import IconButton from './iconButton'
import { useNavigation } from '@react-navigation/native'

import { useSelector } from 'react-redux';
import { getLimitedMode } from '../../redux/limitedModeSlice';

const HomeButton = () => {
  const navigation = useNavigation();
  const isLimitedModeEnabled = useSelector(getLimitedMode);

  const homePressed = () => {
    navigation.reset({
      index: 0,
      routes: [{ name: isLimitedModeEnabled ? 'HomeLimited' : 'Home' }]
    });
  };

  return (
    <IconButton title='Home' icon='home' onPress={homePressed} />
  )
}

export default HomeButton
