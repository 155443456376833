import React from 'react'
import { StyleSheet, View } from 'react-native'
import { getPixelValue } from '../../theme/styles'
import values from '../../theme/values'

const Footer = ({
  leftContent,
  centerContent,
  rightContent,
  height
}) => {

  const containerStyles = [
    styles.container,
    { height }
  ]

  return (
    <View style={containerStyles}>
      <View style={styles.leftContainer}>
        {leftContent}
      </View>
      <View style={styles.centerContainer}>
        {centerContent}
      </View>
      <View style={styles.rightContainer}>
        {rightContent}
      </View>
    </View>
  );
}

export default Footer

const styles = StyleSheet.create({
  container: {
    backgroundColor: values.laMidBlue,
    flexDirection: 'row'
  },
  centerContainer: {
    flex: 2,
    justifyContent: 'center',
    alignItems: 'center'
  },
  leftContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  rightContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingRight: getPixelValue(20)
  },
  time: {
    fontSize: getPixelValue(28),
    fontWeight: 'bold',
    color: values.laWhite
  },
  date: {

  }
})
