import React, { useEffect, useState } from 'react'
import { StyleSheet, Text } from 'react-native'

import moment from 'moment'
import values from '../../theme/values'
import { typography } from '../../theme/styles'

const getTime = () => moment().format('h:mma');

const Time = ({ style }) => {
  const [time, setTime] = useState(getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [])

  return (
    <>
      <Text style={[typography.bold, styles.time, style]}>{time}</Text>
    </>
  )
}

export default Time

const styles = StyleSheet.create({
  time: {
    color: values.laWhite
  }
})
