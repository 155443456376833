import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLimitedModeEnabled: false,
};

const limitedModeSlice = createSlice({
  name: 'limitedMode',
  initialState,

  reducers: {
    setLimitedMode: (state, action) => {
      state.isLimitedModeEnabled = action.payload;
    }
  }
});

// these methods only modify the state
export const { setLimitedMode } = limitedModeSlice.actions;

// these methods only read the state
export const getLimitedMode = (state) => state.limitedMode.isLimitedModeEnabled;

export default limitedModeSlice.reducer;
