import React, { useEffect } from 'react'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { Provider } from 'react-redux'
import { store } from './redux/store'

import Chat from './screens/chat/chat'
import Chats from './screens/chats/chats'
import Contact from './screens/contact/contact'
import DiaryAlert from './screens/diaryAlert/diaryAlert'
import Home from './screens/home/home'
import HomeLimited from './screens/home/homeLimited'
import IncomingCallAlert from './screens/incomingCallAlert/incomingCallAlert'
import Login from './screens/login/login'
import Message from './screens/message/message'
import VideoCall from './screens/videoCall/videoCall'
import reminders from './data/reminders'
import contacts from './data/contacts'

const MainStack = createStackNavigator();

const navigationRef = React.createRef();

const Index = () => {

  return (
    <Provider store={store}>
      <NavigationContainer ref={navigationRef}>
        <MainStack.Navigator screenOptions={{ animationEnabled: false, headerShown: false }}>
          <MainStack.Screen name='Login' component={Login} listeners={({navigation, route}) => ({
            beforeRemove: (e) => onLoginRemove(navigation, e)
          })} />
          <MainStack.Screen name='Home' component={Home} />
          <MainStack.Screen name='Video' component={VideoCall} />
          <MainStack.Screen name='HomeLimited' component={HomeLimited} />
          <MainStack.Screen name='ReminderAlert' component={DiaryAlert} />
          <MainStack.Screen name='IncomingCallAlert' component={IncomingCallAlert} />
          <MainStack.Screen name='Chats' component={Chats} />
          <MainStack.Screen name='Contact' component={Contact} />
          <MainStack.Screen name='ReminderDetails' component={DiaryAlert} />
          <MainStack.Screen name='Chat' component={Chat} />
          <MainStack.Screen name='Message' component={Message} />
        </MainStack.Navigator>
      </NavigationContainer>
    </Provider>
  )
}

const onLoginRemove = (navigation, event) => {
  setTimeout(() => {
    navigation.navigate('ReminderAlert', { reminder: reminders[0], isAlert: true });
    setTimeout(() => {
      navigation.navigate('IncomingCallAlert', { contact: contacts[3] });
    }, 7000);
  }, 7000);
}

export default Index
