import React from 'react'
import IconButton from './iconButton'
import { useNavigation } from '@react-navigation/native'

const BackButton = () => {
  const navigation = useNavigation()

  const backPressed = () => {
    navigation.goBack()
  }

  return (
    <IconButton title='Back' icon='back' onPress={backPressed} />
  )
}

export default BackButton
