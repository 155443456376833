import React, { useState, useRef } from 'react'
import { Text, StyleSheet, View, TouchableOpacity } from 'react-native'
import BackButton from '../../components/buttons/backButton'
import HubLayout from '../../layouts/hubLayout'
import { GiftedChat } from 'react-native-gifted-chat'
import contacts from '../../data/contacts'
import ChatBubble from './chatBubble'
import messages from '../../data/messages'
import { getPixelValue, typography } from '../../theme/styles'
import values from '../../theme/values'
import ScrollButtons from '../../components/buttons/scrollButtons'
import { useNavigation } from '@react-navigation/core'
import HomeButton from '../../components/buttons/homeButton'

const WriteMessageButton = ({ goToMessageScreen }) => {

  return (
      <TouchableOpacity style={styles.writeMessageButton} onPress={goToMessageScreen}>
        <Text style={[typography.medium, styles.writeMessageButtonTitle]}>Write a message</Text>
      </TouchableOpacity>
  );
}

const Chat = ({ route }) => {

  const navigation = useNavigation();

  const flatListRef = useRef(null);
  const { contact } = route.params;
  
  contact.avatar = contact.image;

  // mocking messages user - we're using the same messages for all users for PoC purposes
  messages.forEach(m => m.user = m.user._id != 1 ? contact : m.user);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(getPixelValue(100));

  const goToMessageScreen = () => {
    navigation.navigate('Message', { contact });
  }

  return (
    <HubLayout
      leftHeaderContent={<BackButton />}
      rightHeaderContent={<HomeButton />}
      centerHeaderContent={<Text style={[typography.bold, styles.pageTitle]}>Message {contact.name}</Text>}
      displayFooter={false}
    >
      <View style={styles.scrollButtonsContainer}>
        <ScrollButtons 
          scrollViewRef={flatListRef}
          elementHeight={getPixelValue(220)}
          reversed={true}
          scrollPosition={scrollPosition}
          scrollViewHeight={scrollHeight}
          curvedEdges={false} />
      </View>
      <GiftedChat
        renderUsernameOnMessage={true}
        renderInputToolbar={() => null}
        renderMessage={(props) => <ChatBubble {...props} onReply={goToMessageScreen} />}
        renderAvatar={() => null}
        listViewProps={{ ref: flatListRef, onScroll: e => {
          setScrollPosition(e.nativeEvent.contentOffset.y);
          setScrollHeight(e.nativeEvent.contentSize.height - e.nativeEvent.layoutMeasurement.height);
          } }}
        messages={messages.sort((a, b) => b.createdAt - a.createdAt)}
        user={{
          _id: 1,
        }}
      />
      <WriteMessageButton goToMessageScreen={goToMessageScreen} />
    </HubLayout>
  )
}

export default Chat

const styles = StyleSheet.create({
  writeMessageButton: {
    backgroundColor: values.laLightBlue,
    height: getPixelValue(96),
    alignItems: 'center',
    justifyContent: 'center'
  },
  writeMessageButtonTitle: {
    color: values.laBaseBlue
  },
  scrollButtonsContainer: {
    height: getPixelValue(88)
  },
  pageTitle: {
    color: values.laWhite
  }
});
