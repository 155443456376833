import React from 'react'
import { StyleSheet, View, TextInput, Text, TouchableOpacity } from 'react-native'
import { useNavigation } from '@react-navigation/core'

import Logo from '../../components/logo'
import values from '../../theme/values'
import { getPixelValue, typography } from '../../theme/styles'

import { useDispatch } from 'react-redux';
import { setLimitedMode } from '../../redux/limitedModeSlice';

const Login = () => {
  const dispatch = useDispatch();

  const [username, setUsername] = React.useState("");
  const [password, onPasswordChanged] = React.useState("");

  const navigation = useNavigation();
  const submit = () => {
    if (username === "limited") {
      dispatch(setLimitedMode(true));
      navigation.replace('HomeLimited');
    } else {
      navigation.replace('Home');
    }
  };

  return (
    <View style={styles.container}>
      <Logo width={getPixelValue(225)} height={getPixelValue(158)}/>
      <View style={styles.item}>
        <Text style={[ typography.regular, styles.label ]} >Email address</Text>
        <TextInput style={styles.input} value={username} onChangeText={e => setUsername(e)} placeholder="Type your email address here" />
      </View>
      <View style={styles.item}>
        <Text style={[ typography.regular, styles.label ]} >Password</Text>
        <TextInput style={styles.input} value={password} onChangeText={onPasswordChanged} placeholder="Type your password here" secureTextEntry={true} />
      </View>
      <View style={styles.item}>
        <TouchableOpacity style={styles.button} onPress={submit}>
          <Text style={typography.medium} >Start Link-ages</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

export default Login

const styles = StyleSheet.create({
  container: {
    height: "100%",
    backgroundColor: values.laMidBlue,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingTop: getPixelValue(32),
    paddingLeft: getPixelValue(32),
    paddingRight: getPixelValue(32),
    paddingBottom: getPixelValue(32)
  },
  label: {
    color: values.laWhite,
    fontSize: getPixelValue(40),
    marginBottom: getPixelValue(10)
  },
  input: {
    fontSize: getPixelValue(32),
    backgroundColor: values.laWhite,
    padding: getPixelValue(20),
    borderRadius: values.smallBorderRadius
  },
  button: {
    backgroundColor: values.laLightBlue,
    borderRadius: values.smallBorderRadius,
    padding: getPixelValue(28),
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  item: {
    width: "55%",
    height: getPixelValue(100),
    justifyContent: 'flex-end'
  }
});
