import React from 'react'
import { StyleSheet, Text, View, Image } from 'react-native'
import values from '../../theme/values'
import { getPixelValue, typography } from '../../theme/styles'
import icons from '../../data/icons'

const MessageNotification = ({ count = '' }) => {
  return (
    <View style={styles.notificationCountContainer}>
      <Text style={[typography.bold, styles.notificationCount]}>
        {count}
      </Text>
        <Image source={icons['mail-white']} style={styles.iconMsg} />
    </View>
  )
}

export default MessageNotification

const styles = StyleSheet.create({
  notificationCountContainer: {
    backgroundColor: values.laRed,
    flexDirection: 'row',
    padding: getPixelValue(15),
    position: 'absolute',
    top: getPixelValue(0),
    right: getPixelValue(0),
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomLeftRadius: values.largeBorderRadius,
    shadowColor: "rgba(0, 0, 0, 0.37)",
    shadowOffset: {
      width: 0,
      height: getPixelValue(6)
    },
    shadowRadius: getPixelValue(10),
    shadowOpacity: 1
  },
  notificationCount: {
    color: values.laWhite
  },
  iconMsg: {
    width: getPixelValue(45),
    height: getPixelValue(36),
    marginLeft: getPixelValue(10)
  }
})
