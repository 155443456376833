import React from 'react'
import { StyleSheet, Text, Image, View } from 'react-native'
import BackButton from '../../components/buttons/backButton'
import CardButton from '../../components/buttons/cardButton'
import HomeButton from '../../components/buttons/homeButton'
import HubLayout from '../../layouts/hubLayout'
import { getPixelValue, typography } from '../../theme/styles'
import values from '../../theme/values'
import { useNavigation } from '@react-navigation/core'

import { useSelector } from 'react-redux';
import { getLimitedMode } from '../../redux/limitedModeSlice';

const Contact = ({ route }) => {
  const isLimitedModeEnabled = useSelector(getLimitedMode);
  const { contact } = route.params;

  const imageSource = contact.image;

  const navigation = useNavigation();

  const onVideoPressed = () => {
    navigation.navigate('Video', { contact });
  }

  const onMessagePressed = () => {
    navigation.navigate('Chat', { contact });
  }

  return (
    <HubLayout
      leftHeaderContent={<BackButton />}
      centerHeaderContent={<Text style={[typography.bold, styles.title]}>Chat with {contact.name}</Text>}
      rightHeaderContent={<HomeButton />}
      displayFooter={false}
    >
      <View style={styles.container}>
        <View style={styles.topRow}>
          <Image style={styles.image} source={imageSource} />
        </View>
        <View style={styles.bottomRow}>
          <CardButton
            icon='videocall'
            title='Video call'
            iconStyle={{ width: getPixelValue(171) }}
            onPress={onVideoPressed} />
          { !isLimitedModeEnabled ?
            <CardButton
              icon='mail'
              title='Message'
              style={styles.messageButton}
              iconStyle={{ width: getPixelValue(142.5) }}
              notificationCount={contact.notifications}
              onPress={onMessagePressed} />
          : null }
        </View>
      </View>
    </HubLayout>
  )
}

export default Contact

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  title: {
    color: values.laWhite
  },
  topRow: {
    marginTop: getPixelValue(56),
    alignItems: 'center'
  },
  image: {
    width: getPixelValue(360),
    height: getPixelValue(360),
    borderRadius: getPixelValue(24),
    shadowColor: "rgba(0, 0, 0, 0.1)",
    shadowOffset: {
      width: 0,
      height: getPixelValue(4)
    },
    shadowRadius: getPixelValue(10),
    shadowOpacity: 1
  },
  bottomRow: {
    marginTop: getPixelValue(115),
    flexDirection: 'row',
    alignSelf: 'center'
  },
  messageButton: {
    marginLeft: getPixelValue(180)
  }
});
