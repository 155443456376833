const reminders = [{
  title: 'Call with Dr. Berg (Medication)',
  description: 'Video Call Dr.Berg to discuss your blood medication',
  date: 'Today',
  time: '11:30am',
  isUrgent: true
}, {
  title: 'Shopping trip with Sarah and Mark in M&S',
  description: 'Mark will pick you up from your home to go shopping',
  date: 'Today',
  time: '13:15am',
  isUrgent: false
}, {
  title: 'Video chat with Jessie',
  description: 'Video chat with Jessie',
  date: 'Today',
  time: '15:00am',
  isUrgent: false
}, {
  title: 'Take medication',
  description: 'Take your blood medication',
  date: 'Today',
  time: '16:45am',
  isUrgent: false
}, {
  title: 'Take medication 2',
  description: 'Take your heart medication',
  date: 'Today',
  time: '16:50am',
  isUrgent: false
}];

export default reminders;