import React, { useEffect, useRef, useState } from 'react'
import { Text, StyleSheet, View, TextInput, Keyboard } from 'react-native'
import { useNavigation } from '@react-navigation/core'
import { ScrollView } from 'react-native-gesture-handler'

import BackButton from '../../components/buttons/backButton'
import ColorButton from '../../components/buttons/colorButton'
import HomeButton from '../../components/buttons/homeButton'
import ScrollButtons from '../../components/buttons/scrollButtons'
import messages from '../../data/messages'
import HubLayout from '../../layouts/hubLayout'
import { getPixelValue, typography } from '../../theme/styles'
import values from '../../theme/values'
import Avatar from '../chat/avatar'

const Message = ({ route }) => {
  const scrollViewRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(getPixelValue(100));
  const [messageText, setMessageText] = useState('That would be wonderful. Could we have a call at 2pm? I have a call with your mum a little earlier also!');

  const { contact } = route.params;
  const message = messages.filter(m => m.user._id != 1).sort((a, b) => b.createdAt - a.createdAt)[0];

  const navigation = useNavigation();

  const onSend = () => {
    console.log(messages);

    messages.push({
      _id: messages[0]._id + 1,
      text: messageText,
      createdAt: new Date(),
      state: 'sending',
      user: {
        _id: 1
      }
    });

    console.log(messages);
    navigation.navigate('Chat', { contact });
  };

  const onCancel = () => {
    Keyboard.dismiss();
  };

  const [showCancel, setShowCancel] = React.useState(false);

  useEffect(() => {
    const showListener = Keyboard.addListener('keyboardDidShow', e => setShowCancel(true));
    const hideListener = Keyboard.addListener('keyboardDidHide', e => setShowCancel(false));

    return () => {
      showListener.remove();
      hideListener.remove();
    }
  });

  return (
    <HubLayout
    leftHeaderContent={<BackButton />}
    rightHeaderContent={<HomeButton />}
    centerHeaderContent={<Text style={[typography.bold, styles.pageTitle]}>Message {contact.name}</Text>}
    displayFooter={false}
    >
      <View style={styles.container}>
        <View style={styles.lastMessageContainer}>
          <Avatar style={styles.avatar} image={contact.image} />
          <View style={styles.lastMessageTextContainer}>
            <Text style={[typography.regular, styles.sentBy]}>
              Sent by {contact.name}
            </Text>
            <ScrollView
              ref={scrollViewRef}
              style={styles.scrollView}
              onScroll={e => {
                setScrollPosition(e.nativeEvent.contentOffset.y);
                setScrollHeight(e.nativeEvent.contentSize.height - e.nativeEvent.layoutMeasurement.height);
              }}>
              <Text style={[typography.regular, styles.sentMessageText]}>
                {message.text}
              </Text>
            </ScrollView>
            <View style={styles.scrollButtonsContainer}>
              <ScrollButtons
                scrollViewRef={scrollViewRef}
                scrollPosition={scrollPosition}
                scrollViewHeight={scrollHeight}
                elementHeight={getPixelValue(130)} />
            </View>
          </View>
        </View>
        <View style={styles.newMessageContainer}>
          <Text style={[typography.medium, styles.typeNewMessageText]}>
            Type your new message
          </Text>
          <TextInput
            style={[typography.regular, styles.textInput]}
            multiline={true}
            value={messageText}
            onChangeText={setMessageText} />

          <View style={styles.buttonContainer}>
            <ColorButton onPress={onSend} title='Send this message' colorScheme='blue' style={styles.sendMessageButton} />
            { showCancel ?
              <ColorButton onPress={onCancel} title='Cancel' colorScheme='red' style={styles.cancelButton} />
            : null }
          </View>
        </View>
      </View>
      
    </HubLayout>
  )
}

export default Message

const styles = StyleSheet.create({
  pageTitle: {
    color: values.laWhite
  },
  container: {
    flexDirection: 'row',
    flex: 1,
  },
  lastMessageContainer: {
  },
  sentBy: {
    color: values.laWhite,
    fontSize: getPixelValue(20),
    marginTop: getPixelValue(20),
    marginLeft: getPixelValue(84)
  },
  scrollView: {
    marginTop: getPixelValue(43),
    marginLeft: getPixelValue(19),
    marginRight: getPixelValue(16),
    marginBottom: getPixelValue(25),
  },
  sentMessageText: {
    color: values.laWhite,
    fontSize: getPixelValue(52),
    flex: 1
  },
  lastMessageTextContainer: {
    backgroundColor: values.laBaseBlue,
    width: getPixelValue(426),
    flex: 1,
    height: 1, // it has to be set to something in order for scrollview to work for some reason
    marginLeft: getPixelValue(32),
    marginTop: getPixelValue(32),
    marginBottom: getPixelValue(55),
    borderRadius: values.largeBorderRadius
  },
  avatar: {
    left: getPixelValue(12),
    top: getPixelValue(9)
  },
  scrollButtonsContainer: {
    height: getPixelValue(88)
  },
  newMessageContainer: {
    marginLeft: getPixelValue(44),
    marginTop: getPixelValue(37),
    flex: 1,
    marginRight: getPixelValue(32),
    marginBottom: getPixelValue(63)
  },
  typeNewMessageText: {
    color: values.laDarkGrey
  },
  textInput: {
    flex: 1,
    fontSize: getPixelValue(52),
    marginTop: getPixelValue(19),
    marginBottom: getPixelValue(19),
    textAlignVertical: 'top'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  sendMessageButton: {
    paddingVertical: getPixelValue(14),
    justifyContent: 'center',
    flexGrow: 9
  },
  cancelButton: {
    paddingVertical: getPixelValue(14),
    justifyContent: 'center',
    flexGrow: 1,
    marginLeft: getPixelValue(20)
  }
});
