import React from 'react'
import { StyleSheet, Image } from 'react-native'
import { getPixelValue } from '../../theme/styles';

const Video = () => {
  const imageSource = require(`../../../assets/living-room.png`);


  return (
    <Image source={imageSource} style={styles.image} />
  );
}

export default Video

const styles = StyleSheet.create({
  image: {
    width: getPixelValue(1920),
    height: getPixelValue(944.6)
  }
});